<template>
  <div class="thank-you-page">
    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
    />

    <v-container fluid>
      <CategoryTitle :category="category" />

      <v-btn
        v-if="!profileAnonymized"
        @click="doConfirm(login, token)"
        color="primary"
        large
        depressed
      >
        {{ $t("anonymizeProfileConfirmed.button") }}
      </v-btn>
      <template v-else
        ><v-alert outlined type="success">
          {{ $t("anonymizeProfileConfirmed.profileAnonymized") }}
        </v-alert>
        <v-btn :to="{ name: 'Home' }" color="primary" large depressed>
          {{ $t("anonymizeProfileConfirmed.goToHomeBtn") }}
        </v-btn>
      </template>
      <ResponseMessage :response="response" />
    </v-container>

    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
    />
  </div>
</template>

<script>
import CategoryTitle from "@/components/category/CategoryTitle";
import ResponseMessage from "@/components/ResponseMessage";

import categoryMixins from "~/mixins/category";
import loginMixins from "~/mixins/login";
import UserService from "~/service/userService";

import { mapActions } from "vuex";

export default {
  name: "AnonymizeConfirmed",
  components: { CategoryTitle, ResponseMessage },
  data() {
    return {
      login: null,
      token: null,
      confirmed: false,
      response: {},
      profileAnonymized: false
    };
  },
  mixins: [categoryMixins, loginMixins],
  methods: {
    ...mapActions({
      doLogout: "cart/doLogout"
    }),
    async doConfirm(login, token) {
      this.response = {};

      let response = await UserService.anonymizeAccountTokenCheck(login, token);

      if (response && response.response && response.response.status == 0) {
        this.profileAnonymized = true;
        await this.doLogout();
      } else {
        this.response = response;
      }
    }
  },
  mounted() {
    if (
      this.$route.query &&
      this.$route.query.login &&
      this.$route.query.token
    ) {
      this.login = this.$route.query.login.replace(" ", "+");
      this.token = this.$route.query.token;
    }
    // this.doConfirm(this.$route.query.login, this.$route.query.token);
  }
};
</script>

<style></style>
